@charset "utf-8";

@import '../../variables/variables.less';
@import '../../style-guide/components/platform-switcher.less';

.no-margin {
	margin: 0 !important;
}

/* container margin mixin */
.container-margin {
	margin-bottom: 88px;

	@media @mobile {
		margin-bottom: 65px;
	}
}

.text-align {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

a {
	&.more {
		text-decoration: none;
		border-bottom: 2px dashed @Blue-v2;
		padding-bottom: 2px;
		color: @Blue-v2;
		&:hover,
		&:active {
			color: #1b61b5;
			border-bottom: 2px dashed #1b61b5;
		}
	}

	&.link {
		color: @Grey-1;
		border-bottom: 1px dotted @Grey-1;
		padding-bottom: 2px;

		&:hover,
		&:active,
		&:visited {
			text-decoration: none;
		}
	}
}

p {
	&.text-small {
		@media @desktop {
			font-size: 16px;
		}
	}
}

.icon-product {
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: @GreenPrimary-v2;
	font-size: 14px;
	font-weight: bold;

	@media @mobile {
		justify-content: center;
	}

	img {
		margin-right: 8px;
	}
}

.win {
	display: flex;
	align-items: center;

	@media @mobile {
		justify-content: center;
	}

	img {
		margin-right: 11px;
		position: relative;
		top: -1px;
	}

	a {
		color: @Grey-1;
	}
}

.desktop,
.mobile {
	display: none;
}

@media @desktop {
	.desktop {
		display: inline-flex;
	}
	#top {
		.desktop {
			&.desktop-hint {
				display: block;
			}
		}
	}
}

@media @tablet, @mobile {
	.mobile {
		display: inline-flex;
	}
	#top {
		.mobile {
			&.mobile-hint {
				display: block;
			}
		}
	}
}

h2 {
	line-height: 1.5;
}

.message-line {
	&.message-line--secondary {
		background: @GreenPrimary-v2;
	}
}

#top {
	.container-margin;
	.span9.text {
		padding-right: 20%;

		@media @mobile {
			padding-right: 0;
		}
	}

	h1 {
		margin-bottom: 16px;
		font-size: 56px;

		@media @mobile {
			font-size: 42px;
		}

		@media @tablet {
			font-size: 38px;
		}
	}

	p {
		margin-bottom: 32px;

		@media @desktop {
			&.top-text {
				font-size: 20px;
			}
		}

		@media @mobile, @tablet {
			font-size: 16px;
			margin-bottom: 0;

			&.win {
				font-size: 12px;
			}
		}

		&.top-text a {
			color: #4B5563;
			text-decoration: none;
			border-bottom: 1px dashed #4B5563;
		}
	}

	.top-hint {
		margin-top: 56px;

		.top-hint-wrapper {
			.top-hint-content {
				display: flex;
				margin-bottom: 56px;

				@media @tablet, @mobile {
					flex-direction: column;
					justify-content: center;
				}

				@media @tablet, @desktop {
				  margin-top: 0;
				  align-items: flex-start;
				}

				.top-hint-award {
					display: flex;
					flex-direction: row;
					margin-top: 26px;
					justify-content: center;
					align-items: center;

					@media @tablet, @desktop {
						margin-top: 0;
					}

					.top-hint-award-text {
						margin-bottom: 0;
						text-align: left;
						font-size: 14px;
						color: @Grey-11;
						padding: 0 10px;

						@media @desktop {
							margin-left: 8px;
						}

						.text-bold {
							font-weight: 700;
						}
					}
				}
			}

			.see-all-link {
				@media @mobile {
					margin-top: 24px;
				}
			}

		}
	}

	// Hero buttons
	.buttons {
		.button-wrapper {
			.button {
				margin-bottom: 0;

				&.large > span {
					@media @tablet, @mobile {
						border-radius: 5px;
						padding: 19px 24px;
						padding-left: 70px;
						font-size: 18px;
						line-height: 1;
						height: 56px;
					}
				}
			}
		}

		@media @tablet, @mobile {
			.android img {
				width: 162px;
			}

			.ios img {
				width: 144px;
			}
		}
	}

	// background desktop
	html[class*="-pc"] & {
		@media @desktop, @tablet {
			background: transparent
				url(../../../i/banner_backgrounds/hero-desktop-gloweb-7026.jpg) top right
				no-repeat;
			background-size: contain;
			margin-bottom: 40px;
		}
	}
	html[class*="-mac"] & {
		@media @desktop, @tablet {
			background: transparent
				url(../../../i/banner_backgrounds/hero-image-mac.jpg) top right
				no-repeat;
			background-size: contain;
			margin-bottom: 40px;
		}
	}
	html[class*="-android"] & {
		@media @desktop, @tablet {
			background: transparent
				url(../../../i/banner_backgrounds/hero-image-android.jpg) top right
				no-repeat;
			background-size: contain;
			margin-bottom: 40px;
		}
	}
	html[class*="-ios"] & {
		@media @desktop, @tablet {
			background: transparent
				url(../../../i/banner_backgrounds/hero-image-ios.jpg) top right
				no-repeat;
			background-size: contain;
			margin-bottom: 40px;
		}
	}

	@media @tablet {
		background-size: 85%;
	}

	// background mobile
	html[class*="js-pc"] & {
		@media @mobile {
			background: transparent
				url(../../../i/banner_backgrounds/hero-mobile-gloweb-7026.jpg) bottom
				center no-repeat;
			background-size: contain;
		}
	}
	html[class*="js-mac"] & {
		@media @mobile {
			background: transparent
				url(../../../i/banner_backgrounds/hero-image-mac-mobile.jpg) bottom
				center no-repeat;
			background-size: contain;
		}
	}
	html[class*="js-android"] & {
		@media @mobile {
			background: transparent
				url(../../../i/banner_backgrounds/hero-image-android-mobile.jpg) bottom
				center no-repeat;
			background-size: contain;
		}
	}
	html[class*="js-ios"] & {
		@media @mobile {
			background: transparent
				url(../../../i/banner_backgrounds/hero-image-ios-mobile.jpg) bottom
				center no-repeat;
			background-size: contain;
		}
	}

	@media only screen and (min-width: 768px) and (max-width: 1380px) {
		background-size: 85%;
	}

	.container {
		display: flex;
		min-height: 591px;

		@media @desktop-large {
			min-height: 660px;
		}

		@media @mobile {
			padding-top: 40px;
			text-align: center;
			justify-content: center;
		}

		.button {
			@media @mobile, @tablet {
				margin-top: 40px;
			}
		}
	}

	.row {
		justify-content: flex-end;

		// content inside is vertically centered only for larger displays
		@media @desktop {
			align-items: center;
		}

		.text {
			// safety padding to keep space around content
			@media @desktop-large {
				padding-top: 56px;
				padding-bottom: 56px;
			}

			@media @desktop {
				padding-top: 56px;
				padding-bottom: 0;
			}

			@media @tablet {
				padding-top: 56px;
			}
		}

		.img {
			@media @mobile {
				padding-top: 70%;
			}
		}
	}
}

.dot-spacing {
	margin: 0 24px;
	@media @mobile {
		display: none;
	}
}

.section-usp-stripe {
	padding-bottom: 46px;

	.row {
		border: 1px solid #D1D5DB;
		box-shadow: 0px 15px 40px rgba(30, 34, 42, 0.1);
		padding: 24px;
		margin-left: 0;
		margin-right: 0;

		@media only screen and (min-width:1055px) and (max-width:1168px) {
			padding: 48px 24px;
		}

		@media @desktop {
			padding: 48px 32px;
		}

		@media @desktop-large {
			padding: 48px 64px;
		}
	}

	.usp-stripe {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 32px;
		align-items: flex-start;

		@media @mobile, @tablet {
			flex-direction: column;
			gap: 16px;
		}

		.usp-stripe-item {
			display: flex;
			gap: 16px;
			max-width: 25%;
			//align-self: flex-start;

			@media @mobile, @tablet {
				max-width: none;
				//align-items: center;
			}

			img {
				width: 48px;
				height: auto;
				align-self: flex-start;
			}

			span {
				letter-spacing: 2.5px;
				text-transform: uppercase;
				font-weight: 750;
				font-size: 15px;
				line-height: 24px;
				color: #1E222A;
				word-break: break-word;
			}
			body[class*="lng-de"] &, body[class*="lng-nl"] & {
				span {
					@media @desktop {
						letter-spacing: 1px;
					}
					@media @mobile {
						letter-spacing: 1.5px;
					}
				}
			}
		}
	}
}

#help-me-choose {
	.card {
		.tabs {
			.button {
				span {
					&::after {
						content: url('../../../i/homepage/cta-down.svg');
						width: 24px;
						height: 24px;
						margin-left: 8px;
						margin-top: -4px;
					}
				}
			}
		}
	}

	.hmct-product-protection-advanced .js-pc {
		span.more {
			font-size: 20px;
			padding-bottom: 2px;
			color: @Blue-v2;

			a {
				text-decoration: none;
				border-bottom: 2px dashed @Blue-v2;
			}
		}
	}
}

#features {
	.container-margin;

	h4 {
		font-size: 24px;
		margin-bottom: 32px;

		@media @mobile {
			font-size: 18px;
			margin-bottom: 25px;
		}
	}

	a:not(.more) {
		&:hover {
			text-decoration: none;
		}
	}

	p {
		@media @mobile {
			font-size: 14px;
			margin-bottom: 25px;
		}
	}

	.img {
		width: 64px;
		height: 64px;
		margin-bottom: 24px;
	}

	.img-arrow {
		margin-left: 18px;
	}

	.discount {
		position: relative;
		width: 64px;

		@media @mobile {
			margin: 0 auto;
		}

		.label {
			position: absolute;
			left: 48px;
			top: -3px;
			display: inline-block;
			padding: 4px 9px;
			min-width: 48px;
			background-color: @Red-v2;
			color: @White;
			font-size: 14px;
			text-align: center;
		}
	}

	.span4 {
		@media @mobile {
			margin-bottom: 65px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

#is {
	.container-margin;

	.container {
		@media @mobile {
			padding: 0;
		}
	}

	.text {
		.text-align;

		@media @desktop {
			padding-right: 115px;
		}

		@media @mobile {
			margin-bottom: 50px;
			padding: 0 20px;
			text-align: center;
		}
	}

	.img {
		img {
			@media @tablet, @desktop {
				width: 548px;
			}
		}
	}
}

#android-section {
	.container-margin;

	.container {
		@media @mobile {
			padding: 0;
		}
	}

	.text {
		.text-align;

		@media @desktop {
			padding-left: 116px;
			padding-right: 110px;
		}

		@media @tablet {
			padding-left: 90px;
		}

		@media @mobile {
			margin-bottom: 50px;
			padding: 0 20px;
			text-align: center;
		}
	}

	.row {
		@media @mobile {
			flex-direction: column-reverse;
		}
	}
}

#tuneup {
	.container-margin;
	margin-bottom: 128px;

	.container {
		@media @mobile {
			padding: 0;
		}
	}

	text-align: center;

	.row {
		padding-top: 90px;
	}
	.icon-product {
		justify-content: center;
	}

	.button {
		.container-margin;
	}

	.illustration {
		width: 896px;
	}

	.text {
		@media @mobile {
			padding: 0 20px;
		}
	}
}

#vpn {
	.container-margin;
	margin-bottom: 128px;

	.container {
		@media @mobile {
			padding: 0;
		}
	}

	.img {
		padding-left: 0;
		padding-right: 15px;

		@media @mobile {
			padding-right: 0;
		}
	}
	.text {
		.text-align;

		@media @desktop {
			padding-left: 116px;
			padding-right: 125px;
		}

		@media @tablet {
			padding-left: 90px;
		}

		@media @mobile {
			margin-bottom: 50px;
			padding: 0 20px;
			text-align: center;
		}
	}

	.row {
		@media @mobile {
			flex-direction: column-reverse;
		}
	}
}

#business {
	.container-margin;
	@media @tablet {
		margin-bottom: 40px;
	}

	.button {
		span {
			&:after {
				content: url('../../../i/buttons/facelift/arrow-right.svg');
				margin-left: 18px;
				margin-top: 4px;
			}
			&:hover {
				&:after {
					content: url('../../../i/buttons/facelift/arrow-right-blue.svg');
					margin-left: 18px;
					margin-top: 4px;
				}
			}
		}
	}

	.container {
		@media @tablet, @desktop {
			min-height: 649px;
			width: 100%;
			background-image: url('../../../i/homepage/bg-business-v2.jpg');
			background-position: top center;
			background-repeat: no-repeat;
			background-size: contain;
			display: flex;
			align-items: center;

			.mobile-illustration {
				display: none;
			}
		}
	}

	@media @mobile {
		.container {
			text-align: center;
			padding-left: 0;
			padding-right: 0;
		}
	}

	.card {
		background-color: @Blue;

		@media @tablet, @desktop {
			max-width: 548px;
			min-height: 481px;
			padding: 64px;
			margin-left: 113px;
		}

		@media @tablet {
			margin-left: 0;
		}

		@media @mobile {
			min-height: 316px;
			padding: 48px 20px 64px;
		}

		h2,
		p {
			color: @White;
		}

		.card-text {
			margin-bottom: 32px;

			@media @mobile {
				font-size: 14px;
			}

			a.link {
				color: @White;
				border-bottom: 1px dotted @White;
			}
		}
	}
}

/* Awards-card */
#awards-card {
	.container {
		@media @mobile {
			padding-left: 0;
			padding-right: 0;
		}

		.row {
			@media @mobile {
				align-items: center;
			}
		}
	}
	.card {
		background-color: @White;
		text-align: center;
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
		@media @tablet, @desktop {
			margin-right: 20px;
			margin-left: 20px;
			padding: 89px 108px 96px 108px;
		}
		@media @mobile {
			padding: 64px 20px;
			width: 90%;
		}

		h2 {
			margin-bottom: 20px;
			margin-top: 14px;
			@media @mobile {
				margin-bottom: 24px;
				margin-top: 10px;
			}
		}

		p {
			margin-bottom: 20px;
			@media @mobile {
				margin-bottom: 0;
				font-size: 14px;
			}
		}

		.d-sm-none {
			@media @mobile, @tablet {
				display: none;
			}
			@media only screen and (min-width: 1056px) and (max-width: 1250px) {
				display: none;
			}
		}

		.img-award {
			margin: 0 32px;
			@media @mobile, @tablet {
				margin: 0;
			}
		}

		.awards-img {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-top: 36px;

			@media @mobile {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin-top: 22px;
			}

			.awards-icon {
				@media @mobile {
					display: flex;
					flex-direction: row;
					align-items: center;
				}

				img {
					@media @desktop, @tablet {
						height: 65px;
					}
				}
			}

			p {
				font-size: 16px;
				font-weight: 700;
				color: @Black-v2;
				margin-bottom: 0;
				margin-top: 18px;
				@media @mobile {
					font-size: 14px;
					text-align: left;
					margin-left: 16px;
				}
				span {
					font-size: 11px;
					color: @Grey-2;
					@media @mobile {
						font-size: 10px;
					}
				}
			}

			img {
				@media @mobile {
					margin-top: 40px;
					width: 96px;
				}
			}
		}
	}
}
/* /Awards-card */

/* blog-posts */
#blogposts {
	padding-top: 88px;
	padding-bottom: 64px;
	@media @mobile {
		padding-top: 64px;
	}

	.button.secondary > span {
		font-weight: 500;
	}

	.container {
		//overflow-x: hidden;
		padding-bottom: 56px;
		@media @mobile {
			padding-bottom: 48px;
		}
	}

	.post {
		@media @mobile {
			margin-bottom: 24px;
		}
	}

	.row {
		&.blog {
			justify-content: center;
		}
	}

	.carousel-slider {
		.container {
			@media @mobile, @tablet {
				padding-top: 0;
			}
		}
	}

	h2 {
		text-align: left;
		margin-bottom: 0;
		@media @mobile {
			margin-bottom: 24px;
		}
	}
	h4 {
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid @Grey-12;

		@media @mobile, @tablet {
			font-size: 16px;
		}
	}
	img {
		width: 100%;

		@media @mobile, @tablet {
			margin-bottom: 16px;
		}
	}

	p {
		color: @Grey-1;
		text-decoration: none;
		font-size: 14px;
	}

	a {
		&:hover {
			text-decoration: none;
		}

		&.button {
			&.secondary {
				span {
					border-bottom: 2px dashed @Blue-v2;
				}
			}
		}
	}

	.link-all {
		margin-bottom: 0;
		@media @tablet, @desktop {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			padding-bottom: 16px;
		}

		a {
			border-bottom: none;
			&.button {
				display: flex;
				justify-content: flex-end;

				@media @mobile {
					justify-content: flex-start;
				}
			}
		}
	}
}
/* /blog-posts */

#teaser {
	min-height: 120px;
	background-color: @GreenPrimary-v2;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 20px;

	@media @mobile {
		flex-direction: column;
		text-align: center;
		padding: 40px;
	}

	.ico {
		display: flex;
		flex-direction: row;

		p {
			font-size: 24px;
			color: @White;
			font-weight: bold;
			margin: 0;

			@media @mobile {
				font-size: 18px;
				padding: 0 20px;
				font-weight: bold;
			}
		}

		@media @tablet, @desktop {
			margin-right: 64px;
		}

		@media @mobile {
			display: flex;
			flex-direction: column;
			font-size: 18px;
			margin-bottom: 25px;
			align-items: center;
		}

		img {
			width: 32px;
			height: 32px;

			@media @tablet, @desktop {
				margin-right: 20px;
			}

			@media @mobile {
				margin-bottom: 16px;
			}
		}
	}
}

/* localisation */

.pt-br,
.fr-fr,
.fr-ca,
.fr-be,
.fr-ch {
	#top {
		.text {
			@media @tablet {
				margin-right: 70px;
			}

			@media @desktop {
				margin-right: 100px;
			}

			@media @desktop-large {
				margin-right: 0;
			}
		}
	}
}

.fr-fr,
.fr-ca,
.fr-be,
.fr-ch {
	#top {
		.js-mac {
			.top-hint-wrapper {
				img {
					margin-left: 0;
				}
			}
		}
		.js-android {
			.top-hint-wrapper {
				img {
					margin-left: 2px;
				}
			}
		}
	}
}

.lng-en {
	#top {
		.span9.text {
			padding-right: 0;
			h1 {
				padding-right: 20%;

				@media @mobile {
					padding-right: 0;
				}
			}
			.top-text {
				@media @desktop, @tablet {
					width: 82%
				}
			}
		}
	}
}

.trustpilot {
	width: 420px;
	height: 40px;
	margin-right: 40px;
	padding-top: 12px;

	@media @mobile {
		width: 100%;
		margin-top: 24px;
	}

	@media @tablet {
		margin-bottom: 24px;
	}

	.lng-no & {
		@media @desktop {
			width: 496px;
		}
	}

	.lng-es &,
	.lng-cs & {
		@media @desktop {
			width: 528px;
		}
	}

	.lng-de & {
		@media @desktop {
			width: 590px;
		}

		@media @tablet {
			width: 482px;
		}
	}

	.lng-fr &,
	.lng-nl &,
	.lng-sk &,
	.lng-ja &,
	.lng-zh &,
	.lng-ko &,
	.lng-id & {
		@media @desktop {
			width: 508px;
		}
	}

	.lng-it & {
		@media @desktop {
			width: 518px;
		}

		@media @tablet {
			width: 446px;
		}
	}

	.lng-pt &,
	.lng-pl & {
		@media @desktop {
			width: 538px;
		}

		@media @tablet {
			width: 430px;
		}
	}

	.lng-ru & {
		@media @desktop {
			width: 612px;
		}

		@media @tablet {
			width: 458px;
		}
	}

	.lng-tr &,
	.lng-ms & {
		@media @desktop {
			width: 544px;
		}
	}
}



//
//	Jumpshot – Banner –– GLOWEB-8731
//
.jumpshot-banner {
	display: none;	// Banner will be shown via JS (only on first visit)

	position: relative;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-inline-start: 48px;
	padding-inline-end: 48px;

	background-color: #EBF4FF;
	border-bottom: 1px solid #D9E8FA;

	@media @mobile, @tablet {
		padding-top: 12px;
		padding-bottom: 12px;
		padding-inline-start: 16px;
		padding-inline-end: 42px
	}

	&__text {
		color: #1C222E;

		@media @mobile, @tablet {
			font-size: 14px;
			line-height: 20px;
		}

		@media @desktop {
			font-size: 16px;
			line-height: 22px;
			text-align: center;
		}

		a {
			color: #1C222E;
			text-decoration: underline;

			&:hover,
			&:focus {
				text-decoration: none;
				color: #2276D9;
			}
		}
	}

	// Close button
	.button-circle {
		position: absolute;
		top: 8px;
		right: 8px;

		display: block;
		height: 32px;
		width: 32px;
		padding: 0;
	
		background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"%3E%3Cpath d="M13 13L1 1m12 0L1 13" stroke="%234D5566" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"/%3E%3C/svg%3E');
		background-size: 12px;
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		cursor: pointer;
	
		border: 0;
		border-radius: 100%;
		transition: background-color 0.15s;
	
		&:hover { background-color: #c7cdd7; }
		&:focus { outline: none; }

		@media @desktop {
			top: 8px;
			right: 8px;
		}
	}
}
